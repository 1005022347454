.grid-areas {
  grid-template-areas:
    'more'
    'logo'
    'links'
    'quick'
    'social'
    'searches'
    'info';
}

@media (min-width: 768px) {
  .grid-areas {
    grid-template-areas:
      'more more more more more'
      'logo quick social touch touch'
      'links quick social touch touch'
      'searches searches searches info info';
  }
}

.area-searches {
  grid-area: searches;
}

.area-more {
  grid-area: more;
}

.area-logo {
  grid-area: logo;
}
.area-links {
  grid-area: links;
}
.area-quick-links {
  grid-area: quick;
}
.area-social {
  grid-area: social;
}
.area-touch {
  grid-area: touch;
}
.area-copyright {
  grid-area: info;
}
